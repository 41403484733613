import { addMinutes, format, startOfToday } from "date-fns"
import timezones from "src/data/timezones.json"
import { defaultLocale } from "./formatters"

export type TimezoneCode = keyof typeof timezones

export const getOccurrenceOfWeekDayInMonth = (date: Date) => {
  const dayOfMonth = date.getDate()
  return Math.ceil(dayOfMonth / 7)
}

export const getLocalTimezone = () =>
  Intl.DateTimeFormat().resolvedOptions().timeZone as TimezoneCode

export const durationMsToDate = (durationMs: number) => {
  const date = new Date(durationMs)
  return addMinutes(date, date.getTimezoneOffset())
}

export const durationIsoToMs = (isoDuration: string) =>
  new Date(format(startOfToday(), "yyyy-MM-dd") + "T" + isoDuration).getTime() -
  startOfToday().getTime()

export const dateObjToInputString = (date: Date) => format(date, "yyyy-MM-dd'T'HH:mm")

export const getTimezoneLocaleString = (timeZone: string) => {
  const dateString = new Intl.DateTimeFormat(defaultLocale, {
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    month: "numeric",
    timeZone,
    timeZoneName: "short",
    year: "numeric"
  }).format(new Date())

  return dateString.split(" ").pop()
}
